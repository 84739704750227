var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "list", staticClass: "appeal_list" },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title },
          on: { clickTitle: _vm.clickTitle },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.goBack()
              },
            },
            slot: "left",
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { padding: "10px 20px", "box-sizing": "border-box" },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
        ]
      ),
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "items",
            on: {
              click: function ($event) {
                return _vm.showDetail(item.doc_id)
              },
            },
          },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(item.case_name)),
            ]),
            _c("div", { staticClass: "summary" }, [_vm._v(_vm._s(item.text))]),
            _c("div", { staticClass: "bottom_text" }, [
              _vm._v(
                " " +
                  _vm._s(item.court_name) +
                  _vm._s(" /" + item.case_id) +
                  _vm._s(" / " + item.pub_date) +
                  " "
              ),
            ]),
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "load_area" },
        [_c("loading", { attrs: { showflag: _vm.load } })],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }