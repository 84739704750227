<template>
  <div class="appeal_list" ref="list">
    <fb-header slot="header" @clickTitle="clickTitle" :title="title">
      <fb-button
        slot="left"
        icon="back"
        size="small"
        @click="goBack()"
        type="primary"
      >
      </fb-button>
    </fb-header>
    <van-popup v-model="show" position="bottom">
      <div style="padding: 10px 20px;box-sizing:border-box">{{ title }}</div>
    </van-popup>
    <div
      class="items"
      v-for="(item, index) in data"
      :key="index"
      @click="showDetail(item.doc_id)"
    >
      <div class="title">{{ item.case_name }}</div>
      <div class="summary">{{ item.text }}</div>
      <div class="bottom_text">
        {{ item.court_name }}{{ ' /' + item.case_id
        }}{{ ' / ' + item.pub_date }}
      </div>
    </div>
    <div class="load_area">
      <loading :showflag="load" />
    </div>
  </div>
</template>

<script>
import { pydataBase } from '~api-config'
import { mapState } from 'vuex'
import loading from '@/pages/Search/compoments/loading'
export default {
  components: { loading },
  props: ['options', 'lawTitle', 'lawText'],
  data() {
    return {
      data: [],
      load: 2,
      page: 2,
      HasBind: false,
      fn: null,
      state: '',
      show: false
    }
  },
  computed: {
    ...mapState('ana', ['text']),
    title() {
      const title = this.lawTitle + this.lawText
      return title
    }
  },
  methods: {
    showDetail(docId) {
      const words = []
      if (this.lawTitle) {
        words.push(this.lawTitle)
      }
      if (this.lawText) {
        words.push(this.lawText)
      }
      this.$emit('showDetail', docId, words)
    },
    clickTitle() {
      this.show = true
    },
    goBack(e) {
      this.$emit('HideAppealDetail')
    },
    getData(query, callback) {
      const data = {
        sentence: this.text, // 识别文本
        summary: query.summary, //  案由名称
        input_keyword: [], // 用户输入的关键字
        key: query.key, // 分类的key
        condition: [], // 筛选条件
        offset: query.offset, // 页码
        text: query.text // 选中的文字
      }
      if (query.summary === '') {
        data.summary = this.$store.state.ana.caseName.split('x')[0]
      }
      this.$axios
        .post(`${pydataBase}/api/ai/semantic/more`, data)
        .then(callback)
    },
    BindEvent() {
      this.HasBind = true
      const fn = () => {
        if (
          this.$refs.list.scrollTop + this.$refs.list.offsetHeight ===
          this.$refs.list.scrollHeight
        ) {
          this.options.offset_child = this.page++
        }
      }
      this.$refs.list.addEventListener('scroll', fn, false)
      return fn
    },
    RemoveEvent() {
      this.HasBind = false
      this.$refs.list.removeEventListener('scroll', this.fn)
    },
    cancel() {
      this.goBack()
    }
  },
  mounted() {
    this.$emit('update:appeallist', this)
  },
  watch: {
    options: {
      deep: true,
      handler(val) {
        // console.log('95')
        // console.log(val)
        // val 请求参数
        this.load = 1
        if (val.offset_child === 1) {
          this.data = []
        }
        this.getData(val, res => {
          this.data = this.data.concat(res.data.result)
          // console.log('104')
          // console.log(this.data)
          if (res.data.result.length < 10) {
            this.load = 0
            this.page = 2
            this.RemoveEvent()
          } else {
            this.load = 1
            if (!this.HasBind) {
              this.fn = this.BindEvent()
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.appeal_list
  width 100%
  height 100%
  position fixed
  background-color white
  z-index 1000
  overflow auto
  padding 10px
  top -100%
  left -100%
  transition all 0.7s
  & .items
    box-sizing border-box
    border-bottom 1px solid #CCCCCC
    overflow hidden
    margin-top 10px
    & .title
      color black
      font-size 16px
      font-weight normal
      display -webkit-box
      -webkit-line-clamp 3
      -webkit-box-orient vertical
      overflow hidden
    & .summary
      margin-top 5px
      font-size 14px
      color #777
      overflow hidden
      text-overflow ellipsis
      max-height 55px
    & .bottom_text
      color #999999
      font-size 12px
      margin-top 8px
      margin-bottom 10px
  & .load_area
    width 100%
    margin-top 20px
    overflow hidden
</style>
